import './App.scss';
import {useDropzone} from 'react-dropzone'
import Select from 'react-select'
import {useState} from 'react'
import {humanFileSize, isAllValuesEmpty, parseMultipleFiles} from './utils/files'
import _ from 'lodash'
import Papa from 'papaparse'
import {Oval as Loader} from 'react-loader-spinner'

function App() {
    const [files, setFiles] = useState([])
    const [parsedFiles, setParsedFiles] = useState([])
    const [mutualColumns, setMutualColumns] = useState([])
    const [selectedColumns, setSelectedColumns] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const onDrop = async (droppedFiles) => {
        setIsLoading(true)
        const uniqueFiles = _.uniqBy([...files, ...droppedFiles], file => file.path)
        setFiles(preve => uniqueFiles)
        const parsedNewFiles = await parseMultipleFiles(uniqueFiles)
        setParsedFiles(preve => [...preve, ...parsedNewFiles])
        let mutualHeaders = []
        for (const file of parsedNewFiles) {
            const headers = file.meta?.fields || []
            mutualHeaders = mutualHeaders.length ? [...mutualHeaders.filter(column => headers.includes(column))] : [...headers]
        }
        setMutualColumns(prev => mutualHeaders.map(col => ({label: col, value: col})))
        setSelectedColumns(prev => mutualHeaders.map(col => ({label: col, value: col})))
        setIsLoading(false)
    }
    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'text/csv': ['.csv'],
        },
        multiple: true,
        onDrop,
    });
    const handleSelectChange = (selectedOption) => {
        setSelectedColumns(selectedOption);
    }

    const resetHandler = () => {
        window.location.reload()
    }

    const generateAndDownloadFile = (event) => {
        event.preventDefault()
        setIsLoading(true)
        const allFileRowsTrimmed = _.filter(_.flatten(parsedFiles.map(file => file.data)), row => !isAllValuesEmpty(row))
        console.log(allFileRowsTrimmed)
        const csv = Papa.unparse(allFileRowsTrimmed, {
            header: true,
            columns: selectedColumns.map(col => col.value),
        })
        const csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
        let csvURL = ''
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `data_${new Date().toISOString()[0].replace('-', '_')}.csv`);
        } else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        window.open(csvURL, '_blank')
        setIsLoading(false)
    }

    const acceptedFileList = files.map(file => (
        <li key={file.path}>
            {file.path} - {humanFileSize(file.size)}
        </li>
    ))

    return (
        <main className="main-container">
            <form onSubmit={generateAndDownloadFile}>
                <div className='header-wrapper'>
                    <h1>Append and Trim CSV Files</h1>
                    <button onClick={resetHandler}>Reset</button>
                </div>
                <div {...getRootProps({className: 'dropzone'})}>
                    <input
                        {...getInputProps()}
                    />
                    <p>Drag 'n' drop .csv files here, or click to select files</p>
                </div>
                {files.length ? <aside>
                    <h4>Files</h4>
                    <ul>{acceptedFileList}</ul>
                </aside> : <></>}
                <br/>
                {parsedFiles.length ? <>
                    <h4>Columns</h4>
                    <Select
                        closeMenuOnSelect={false}
                        defaultValue={[]}
                        placeholder="Mutual Columns"
                        isMulti
                        onChange={handleSelectChange}
                        value={selectedColumns}
                        options={mutualColumns}
                        noOptionsMessage={() => 'No Mutual Columns'}
                    />
                </> : <></>}
                <br/>
                {isLoading && <Loader
                    visible={true}
                    height="70"
                    width="70"
                    color="#4fa94d"
                    ariaLabel="oval-loading"
                />}
                {parsedFiles.length && !isLoading ? <button type="submit">Download</button> : <></>}
            </form>
        </main>
    );
}

export default App;
