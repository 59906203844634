import Papa from 'papaparse'

export function humanFileSize(bytes, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}


export function parseMultipleFiles(files) {
    const filesData = []
    return Promise.all([...files].map((file) =>
        new Promise((resolve, reject) =>
            Papa.parse(file, {
                header: true,
                worker: true,
                complete: resolve,  // Resolve each promise
                error: reject,
            }),
        )),
    ).then((results) => {
        results.forEach((result, index) => {
            filesData.push(result)
        })
        return filesData
    }).catch((err) => console.log('Something went wrong:', err))
}

export function isAllValuesEmpty(obj) {
    return Object.values(obj).every((v) => v === '')
}